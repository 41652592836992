import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import logo from './logo.png';
import './App.css';

function Naveen() {
  return (
    <div>
      <h2>Naveen's Page</h2>
      {/* Add your Naveen page content here */}
    </div>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-log" alt="logo" />
          <p>+91 7550182943</p>
          <p>naveen@jeayamstructures.com</p>
          <p>No 29, 2nd pillayar kovil street, lakshimipuram,chrompet, chennai-600044.</p>
          <p>jeayamstructures.com</p>

          {/* Add a Link to navigate to the /naveen route */}
          <nav>
            <ul>
              <li>
                <Link to="/naveen">Naveen's Page</Link>
              </li>
            </ul>
          </nav>

          {/* Define the route for /naveen */}
          <Routes>
            <Route path="/naveen" element={<Naveen />} />
          </Routes>
        </header>
      </div>
    </Router>
  );
}

export default App;
